//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-208:_9872,_3788,_2528,_2700,_3396,_8042,_424,_9864;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-208')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-208', "_9872,_3788,_2528,_2700,_3396,_8042,_424,_9864");
        }
      }catch (ex) {
        console.error(ex);
      }